import Image from 'next/image'

export const EmailCodePreview = () => (
  <Image
    src='/email-code-preview.png'
    width={365}
    height={193}
    alt='Email preview'
  />
)
