import { UiNode, UiNodeInputAttributes } from '@ory/client'
import { getNodeId } from '@ory/integrations/ui'
import Box from 'lib/ui/Box'

import * as css from './styles'
import Button from '@/ui/Button'
import Node from '@/ui/Node'
import { customizedNodeData } from '@/ui/Node/utils'
import { FormValues } from '@/utils/ory/types'

interface IFormItem {
  node: UiNode
  disabled: boolean
  onSubmit: (values: any) => Promise<void>
  setValues: (value: any) => void
  values: FormValues
  isRegistration?: boolean
  userEmail?: string
}
const FormItem = ({
  node,
  disabled,
  onSubmit,
  setValues,
  isRegistration,
  values,
  userEmail
}: IFormItem) => {
  const id = getNodeId(node) as keyof FormValues
  const passwordConfirmId = 'password_confirm'
  const attributes = node.attributes as UiNodeInputAttributes

  const isPassword = attributes.name === 'password'
  const isEmail =
    attributes.name === 'traits.email' || attributes.name === 'identifier'

  const handleValueChange = (id: string) => async (value: string) => {
    setValues(prevValues => ({
      ...prevValues,
      [id]: value
    }))
  }

  return (
    <div css={{ display: isEmail ? 'none' : 'initial' }}>
      <Box padding={0} margin={[0, 0, 1]}>
        <Node
          disabled={disabled}
          node={node}
          value={isEmail ? userEmail : values[id]}
          dispatchSubmit={onSubmit}
          setValue={handleValueChange(id)}
        />
      </Box>
      {!isRegistration && isPassword && (
        <div css={css.forgotPasswordStyle}>
          <Button
            buttonSize='md'
            variant='text'
            href={`/recovery?email=${userEmail}`}
          >
            Forgot your password?
          </Button>
        </div>
      )}
      {isRegistration && isPassword && (
        <Box padding={0} margin={[0, 0, 1]}>
          <Node
            disabled={disabled}
            node={customizedNodeData(node, {
              label: 'Confirm password',
              name: passwordConfirmId
            })}
            value={values[passwordConfirmId]}
            setValue={handleValueChange(passwordConfirmId)}
          />
        </Box>
      )}
    </div>
  )
}

export default FormItem
