import { useState } from 'react'

import Card from 'lib/ui/Card'
import Heading from 'lib/ui/Heading'
import PoweredBy from 'lib/ui/Icon'
import { AlternativePaymentsLogo } from 'lib/ui/Logo'
import theme from 'utils/theme'

import LoggingAs from './LoggingAs'
import * as css from './styles'
import { usePartnerContext } from '@/hooks/providers/PartnerProvider'
import Logo, { Layout } from '@/layouts/DefaultLayout/Logo'

interface IAuthFlowLayout {
  children: React.ReactNode
  userEmail?: string
  title?: string
  description?: string | React.ReactNode
  titleImage?: React.ReactNode
  alert?: React.ReactNode
}

const AuthFlowLayout = ({
  children,
  userEmail,
  title,
  description,
  titleImage,
  alert
}: IAuthFlowLayout) => {
  const { brandColor, partner } = usePartnerContext()
  const [customLogoError, setCustomLogoError] = useState(false)

  const hasCustomLogo =
    Boolean(partner?.paymentsPortalCustomization?.logoUrl) && !customLogoError

  return (
    <div css={css.containerStyle}>
      <div css={css.wrapperStyle}>
        {alert && <div>{alert}</div>}
        <Card width={60} styles={css.cardStyle}>
          <div
            data-testid='login-layout'
            css={css.cardContentStyle(brandColor)}
          >
            <div css={{ display: 'flex', justifyContent: 'center' }}>
              {hasCustomLogo ? (
                <Logo
                  partner={partner}
                  layout={Layout.DASHBOARD}
                  setError={setCustomLogoError}
                />
              ) : (
                <AlternativePaymentsLogo color='black' />
              )}
            </div>
            <div css={css.cardBodyStyle}>
              {userEmail && <LoggingAs userEmail={userEmail} />}
              {titleImage && (
                <div css={{ marginTop: theme.spacing(2), marginBottom: '8px' }}>
                  {titleImage}
                </div>
              )}
              <div css={css.cardHeaderStyle}>
                <Heading
                  size='sm'
                  styles={{
                    textAlign: 'center',
                    color: theme.colors.surface500
                  }}
                  margin={0}
                >
                  {title}
                </Heading>
                <p css={css.cardDescriptionStyle}>{description}</p>
              </div>
              {children}
            </div>
          </div>
        </Card>
        {hasCustomLogo && (
          <PoweredBy
            name='poweredByV2'
            width={16.3}
            height={9}
            viewBox='-10 20 120 20'
          />
        )}
      </div>
    </div>
  )
}

export default AuthFlowLayout
