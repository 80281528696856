import Email from '@carbon/icons-react/lib/Email'
import Link from 'next/link'
import { useRouter } from 'next/router'

import * as css from './styles'

const LoggingAs = ({ userEmail }: { userEmail: string }) => {
  const { route } = useRouter()
  const isRegistration = route.includes('/registration')
  return (
    <div css={css.loggingAsStyle}>
      <div css={{ display: 'flex' }}>
        <Email size={20} />
        <div css={css.loggingAsTextStyle}>
          You're {isRegistration ? 'signing up' : 'signing in'} as{' '}
          <span css={css.loggingAsEmailStyle}>{userEmail}</span>
        </div>
      </div>
      <div>
        Not you?{' '}
        <Link href='/logout' css={css.logginAsLinkStyle}>
          Change email
        </Link>
      </div>
    </div>
  )
}

export default LoggingAs
