import { useEffect, useState } from 'react'

import { RegistrationFlow } from '@ory/client'
import { useRouter } from 'next/router'

import AuthFlowLayout from '../AuthFlowLayout'
import AccessYourAccountForm from '@/forms/AccessYourAccountForm'
import ory, { handleFlowError, loginSubmit } from '@/utils/ory'

const AccessYourAccountLayout = ({
  loginChallenge
}: {
  loginChallenge: string
}) => {
  const router = useRouter()
  const { isReady, query } = router
  const { return_to: returnTo, refresh } = query

  const [flow, setFlow] = useState<RegistrationFlow>()

  const handleFlowUpdate = (data: RegistrationFlow) => {
    if (!data) {
      setFlow(null)
      return
    }
    setFlow(data)
  }

  const onSubmit = loginSubmit(flow, router, handleFlowUpdate)

  useEffect(() => {
    if (!isReady || flow) {
      return
    }

    ory
      .createBrowserLoginFlow({
        refresh: Boolean(refresh),
        returnTo: returnTo ? String(returnTo) : undefined
      })
      .then(({ data }) => handleFlowUpdate(data))
      .catch(err => handleFlowError(router, 'login', handleFlowUpdate)(err))
  }, [router])

  // "Please complete the second authentication challenge."
  useEffect(() => {
    if (flow?.ui?.messages?.some(m => m.id === 1010004)) {
      const returnTo = (query.return_to as string) || flow.return_to
      router.push(`/mfa?aal=aal2${returnTo ? `&return_to=${returnTo}` : ''}`)
    }
  }, [flow, router])

  return (
    <AuthFlowLayout
      title='Sign In'
      description='Please enter your email address to continue.'
    >
      <AccessYourAccountForm
        loginChallenge={loginChallenge}
        flow={flow}
        onSubmitSSO={onSubmit}
      />
    </AuthFlowLayout>
  )
}

export default AccessYourAccountLayout
