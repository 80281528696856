import { defaults } from 'lib/layouts/DefaultLayout'
import { createStyles } from 'utils/css'
import { ColorProps } from 'utils/types'

export const containerStyle = createStyles(({ colors }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  alignItems: 'center',
  height: '100%',
  backgroundColor: colors.surface100
}))

export const wrapperStyle = createStyles(({ colors }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: colors.surface100,
  rowGap: '40px'
}))

export const cardStyle = createStyles(({ radii, spacing }) => ({
  textAlign: 'center',
  borderRadius: radii.xl,
  padding: 0,
  margin: spacing(1)
}))

export const cardContentStyle = (brandColor: ColorProps) =>
  createStyles(({ spacing, radii, mediaQuery }) => ({
    padding: spacing([2, 2]),
    borderRadius: `${radii.lg} 0 0 ${radii.lg}`,
    [mediaQuery(defaults.breakpoint)]: {
      padding: `40px 94px 56px 94px`,
      borderLeft: `8px solid ${brandColor.hex}`
    }
  }))

export const cardBodyStyle = createStyles(() => ({
  marginTop: '40px'
}))

export const cardHeaderStyle = createStyles(({ colors, typography }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: colors.surface400,
  rowGap: '8px',
  fontSize: typography.body.sizes.lg,
  marginBottom: '24px'
}))

export const cardDescriptionStyle = createStyles(() => ({
  margin: '0!important'
}))

export const titleImageContainerStyle = createStyles(({ spacing }) => ({
  marginTop: spacing(2),
  marginBottom: '8px'
}))

export const loggingAsStyle = createStyles(
  ({ spacing, colors, typography, radii }) => ({
    border: `1px solid ${colors.genericSoftDarker}`,
    backgroundColor: colors.genericSoft,
    padding: spacing([0.5, 1]),
    marginBottom: '24px',
    color: colors.surface600,
    fontSize: typography.body.sizes.sm,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: radii.md,
    columnGap: spacing(1),
    '& svg': {
      color: colors.grey20
    }
  })
)

export const loggingAsEmailStyle = createStyles(({ colors }) => ({
  color: colors.surface500,
  fontWeight: 700
}))

export const loggingAsTextStyle = createStyles(({ spacing }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row',
  justifyContent: 'center',
  columnGap: spacing(0.3),
  flex: 1,
  marginBottom: '4px'
}))

export const logginAsLinkStyle = createStyles(({ colors }) => ({
  color: colors.blueLink,
  fontWeight: 600
}))
