import { createStyles } from 'utils/css'

export const forgotPasswordStyle = createStyles(({ colors, spacing }) => ({
  textAlign: 'right',
  marginBottom: spacing(1),
  '& a span': {
    color: colors.blueLink,
    fontWeight: 600,
    textDecoration: 'underline'
  }
}))
