import { FormEvent, memo, useState } from 'react'

import { getNodeId } from '@ory/integrations/ui'
import Box from 'lib/ui/Box'
import toast from 'react-hot-toast'

import FormItem from './FormItem'
import useFormSubmit from '@/hooks/useFormSubmit'
import FormDivider from '@/ui/FormDivider'
import Messages from '@/ui/Messages'
import Node from '@/ui/Node'
import { customizedNodeData, isPasswordless } from '@/ui/Node/utils'
import SkeltonLoader from '@/ui/SkeltonLoader'
import { emptyState } from '@/utils/ory'
import { FormProps, FormValues, NodeGroup } from '@/utils/ory/types'

const LoginForm = <T extends FormValues>(props: FormProps<T>) => {
  const { flow, userEmail, credentials = [] } = props
  const [values, setValues] = useState(emptyState<T>())
  const { handleSubmit, isLoading } = useFormSubmit(props.onSubmit)

  const nodes = flow?.ui?.nodes?.reduce<NodeGroup>((acc, node) => {
    acc[node.group] = [...(acc[node.group] || []), node]
    return acc
  }, {})
  const nodesArr = [
    ...(nodes?.default || []),
    ...(nodes?.password || []),
    ...(nodes?.code || [])
  ].filter(node =>
    credentials.length > 0
      ? node.group === 'default' || credentials.includes(node.group)
      : true
  )
  const hasCode = Boolean(nodesArr.find(node => node.group === 'code'))
  const hasPassword = Boolean(nodesArr.find(node => node.group === 'password'))

  const isSSOVerification = flow?.ui?.messages?.some(
    message => message.id === 1010003
  )
  const isRegistration = flow?.ui?.action.includes('registration')
  const passwordConfirmName = 'password_confirm'
  const onSubmit = isRegistration
    ? handleSubmitWithPasswordConfirmation
    : handleSubmit

  async function handleSubmitWithPasswordConfirmation(
    event: FormEvent<HTMLFormElement>
  ) {
    event.stopPropagation()
    event.preventDefault()

    const submitter = (event.nativeEvent as unknown as SubmitEvent)
      .submitter as HTMLButtonElement
    const isSubmitterPasswordless = submitter?.value === 'code'
    const isSubmitterSSO = submitter?.name === 'provider'

    if (isSubmitterPasswordless || isSubmitterSSO) return handleSubmit(event)

    const passwordConfirmation = values[passwordConfirmName] || ''
    const password = values['password'] || ''

    if (password !== passwordConfirmation) {
      toast.error('Password confirmation does not match')
      return
    }

    handleSubmit(event)
  }

  if (!flow) {
    return <SkeltonLoader btnItems={2} formItems={2} />
  }

  return (
    <>
      {flow?.ui?.messages?.length > 0 && (
        <Box padding={0} margin={[0, 0, 1]}>
          <Messages messages={flow.ui.messages} />
        </Box>
      )}
      <form
        action={flow?.ui.action}
        method={flow?.ui.method}
        onSubmit={onSubmit}
      >
        {hasCode &&
          nodesArr.map((node, k) => (
            <div
              key={k}
              css={{ display: isPasswordless(node) ? 'initial' : 'none' }}
            >
              <FormItem
                onSubmit={onSubmit}
                values={values}
                setValues={setValues}
                node={
                  isPasswordless(node)
                    ? customizedNodeData(node, {
                        label: `Sign ${
                          isRegistration ? 'up' : 'in'
                        } without password`
                      })
                    : node
                }
                userEmail={userEmail}
                isRegistration={isRegistration}
                disabled={isLoading}
              />
            </div>
          ))}
      </form>
      {hasCode && hasPassword && <FormDivider />}
      <form
        action={flow?.ui.action}
        method={flow?.ui.method}
        onSubmit={onSubmit}
      >
        {(hasCode || hasPassword) &&
          nodesArr
            .filter(node => !isPasswordless(node))
            .map((node, k) => (
              <FormItem
                key={k}
                onSubmit={onSubmit}
                values={values}
                setValues={setValues}
                node={node}
                userEmail={userEmail}
                isRegistration={isRegistration}
                disabled={isLoading}
              />
            ))}
      </form>
      <form
        action={flow?.ui.action}
        method={flow?.ui.method}
        onSubmit={onSubmit}
      >
        {(isSSOVerification || credentials.includes('oidc')) && (
          <>
            {Boolean(nodes?.oidc?.length) && <FormDivider />}
            {nodes?.oidc?.map((node, k) => {
              const id = getNodeId(node) as keyof FormValues
              return (
                <Box padding={0} margin={[0, 0, 0.5]} key={k}>
                  <Node
                    disabled={isLoading}
                    node={node}
                    value={values[id]}
                    dispatchSubmit={onSubmit}
                    setValue={async (value: string) => {
                      setValues(prevValues => ({
                        ...prevValues,
                        [id]: value
                      }))
                    }}
                  />
                </Box>
              )
            })}
          </>
        )}
      </form>
    </>
  )
}
export default memo(LoginForm)
